<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <div style="overflow: auto;" v-if="isPdf">
            <van-loading v-show="!pageTotalNum" type="spinner" size="24px" vertical>加载中...</van-loading>
            <pdf ref="pdf" style="margin:auto;min-height:40%" :src="getFilePath" :page="pageNum" :rotate="pageRotate"
                @password="password" @progress="loadedRatio = $event" @error="pdfError" @num-pages="pageTotalNum = $event"
                @link-clicked="pageNum = $event"></pdf>
            <div v-show="pageTotalNum" style="text-align:center; position: sticky;bottom: 0;left: 0;right: 0;">
                <div>当前页数/总页数：{{ pageNum }}/{{ pageTotalNum }}</div>
                <div
                    style="display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;width: 80vw;margin: auto;">
                    <van-button round block type="info" class="btn" @click="prePage">上一页</van-button>
                    <van-button round block type="info" class="btn" @click="nextPage">下一页</van-button>
                    <van-button round block type="info" class="btn" @click="scaleD">放大</van-button>
                    <van-button round block type="info" class="btn" @click="scaleX">缩小</van-button>
                    <van-button round block type="info" class="btn" @click="clock">顺时针旋转</van-button>
                    <van-button round block type="info" class="btn" @click="counterClock">逆时针旋转</van-button>

                    <!-- <van-button round block type="info" class="btn" @click="fileDownload(getFilePath, 'pdf文件')">下载</van-button> -->
                </div>
            </div>
        </div>
        <div v-else-if="isWord" id="wordView" v-html="vHtml"></div>

        <iframe v-else-if="isExcel" width="100%" :src="`${getFilePath}`" frameborder="0" style="height:90vh"
            sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation"></iframe>

        <div v-else-if="isTxt" class="txt-preview" v-html="vHtml" style="white-space: pre-wrap; "></div>
        <!-- <iframe v-else-if="isTxt" width="100%" :src="getFilePath" frameborder="0" style="height:90vh"></iframe> -->
        <!-- <div style="min-width:100vw;min-height:100vh;background: #fff;">
            {{ vHtml }}
        </div> -->
    </div>
</template>
<script>
import Vue from 'vue'
import headTitle from "../components/headTitle.vue";
import pdf from 'vue-pdf'
import mammoth from 'mammoth';
import { Button, Loading } from 'vant'

Vue.use(Button)
Vue.use(Loading);
export default {
    name: "preview",
    components: {
        headTitle,
        pdf,
    },
    data() {
        return {
            isPdf: false,
            // 总页数
            pageTotalNum: 1,
            // 当前页数
            pageNum: 1,
            // 加载进度
            loadedRatio: 0,
            // 页面加载完成
            curPageNum: 0,
            // 放大系数 默认百分百
            scale: 100,
            // 旋转角度 ‘90’的倍数才有效
            pageRotate: 0,
            isWord: false,
            isExcel: false,
            isTxt: false,
            isLoading: true,
            title: '文件预览',
            getFilePath: '',
            vHtml: '',
            routerPath: ''
        }
    },
    // watch: {
    //     'pageTotalNum': {
    //         handler(newly) {
    //             console.log('监听新旧值', newly)
    //         }
    //     }
    // },

    mounted() {
        let url = this.$route.query.url
        this.routerPath = this.$route.query.routerPath
        console.log('0.0', url)
        if (url.indexOf('.pdf') !== -1) {
            this.isPdf = true
            // this.getFilePath = url 
            this.getFilePath = pdf.createLoadingTask({
                url: url,
                cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',//引入pdf.js字体
                cMapPacked: true
            })
        } else if (url.indexOf('.docx') !== -1 || url.indexOf('.doc') !== -1) {
            this.isWord = true
            this.readExcelFromRemoteFile(url)
        } else if (url.indexOf('.xlsx') !== -1 || url.indexOf('.ppt') !== -1 || url.indexOf('.xls') !== -1) {
            this.isExcel = true
            this.getFilePath = `https://view.officeapps.live.com/op/view.aspx?src=${url}`

        } else if (url.indexOf('.txt') !== -1) {
            this.isTxt = true
            let xhr = null, okStatus = document.location.protocol === 'file' ? 0 : 200
            xhr = new XMLHttpRequest()
            xhr.open('GET', url, false)
            xhr.overrideMimeType('text/html;charset=utf-8')
            xhr.send(null)
            this.vHtml = xhr.status === okStatus ? xhr.responseText : null
        }
        console.log('pdf', this.routerPath)
    },
    methods: {
        // pdf插件使用的方法
        //放大
        scaleD() {
            this.scale += 5;
            this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
        },

        //缩小
        scaleX() {
            // scale 是百分百展示 不建议缩放
            this.scale += -5;
            console.log(parseInt(this.scale) + "%");
            this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
        },
        // 切换上一页
        prePage() {
            var p = this.pageNum;
            p = p > 1 ? p - 1 : this.pageTotalNum;
            this.pageNum = p;
        },
        // 切换下一页
        nextPage() {
            var p = this.pageNum;
            p = p < this.pageTotalNum ? p + 1 : 1;
            this.pageNum = p;
        },
        // 顺时针选中角度
        clock() {
            this.pageRotate += 90;
        },
        // 逆时针旋转角度
        counterClock() {
            this.pageRotate -= 90;
            console.log(this.pageRotate)
        },
        // pdf 有密码 则需要输入秘密
        password(updatePassword, reason) {
            updatePassword(prompt('password is "test"'));
            console.log("...reason...");
            console.log(reason);
            console.log("...reason...");
        },
        // 异常监听
        pdfError(error) {
            console.error(error);
        },
        //下载PDF
        fileDownload(data, fileName) {
            let blob = new Blob([data], {
                //type类型后端返回来的数据中会有，根据自己实际进行修改
                type: "application/pdf;charset-UTF-8",
            });
            let filename = fileName || "pdf.pdf";
            if (typeof window.navigator.msSaveBlob !== "undefined") {
                window.navigator.msSaveBlob(blob, filename);
            } else {
                var blobURL = window.URL.createObjectURL(blob);
                // 创建隐藏<a>标签进行下载
                var tempLink = document.createElement("a");
                tempLink.style.display = "none";
                tempLink.href = blobURL;
                tempLink.setAttribute("download", filename);
                if (typeof tempLink.download === "undefined") {
                    tempLink.setAttribute("target", "_blank");
                }
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobURL);
            }
        },
        readExcelFromRemoteFile: function (url) {
            var vm = this;
            var xhr = new XMLHttpRequest();
            xhr.open("get", url, true);
            xhr.responseType = "arraybuffer";
            xhr.onload = function () {
                // console.log(mammoth.convertToHtml({ arrayBuffer: new Uint8Array(xhr.response) }))
                if (xhr.status == 200) {
                    mammoth.convertToHtml({ arrayBuffer: new Uint8Array(xhr.response) }).then(function (resultObject) {
                        vm.$nextTick(() => {
                            // document.querySelector("#wordView").innerHTML =
                            //   resultObject.value;
                            vm.vHtml = resultObject.value;
                        });
                    });
                }
            };
            xhr.send();
        },

    }
};
</script>
<style lang="less" scoped>
.bkColor {
    width: 100%;
    min-height: 100vh;
    background: #f6f6f6;
    position: relative;
}

#wordView {
    width: 100%;
    height: 100%;
    font-family: "Arial";
    font-size: 15px;
    color: #333;
    line-height: 1.5;
    text-indent: 2em;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    margin: 10px 0;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fff;


}

#wordView /deep/ table {
    table-layout: fixed !important;
    border-collapse: collapse !important;
}

#wordView /deep/ tr {
    text-align: center;
    vertical-align: middle;

}

#wordView /deep/ td {
    height: 40px;
    border: 1px solid #ddd;
}

#wordView /deep/ img {
    width: 100%;
    height: 100%;
}


.btn {
    width: 120px;
    margin-bottom: 10px;
}

/deep/ .van-loading {
    position: fixed;
    z-index: 999;
    top: 40%;
    left: 45%;
}
</style>



